@use 'src/css/breakpoint.scss' as *;

.pseudoLink {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 30px;
  font-size: 1.6rem;
  color: var(--header-color);
  cursor: pointer;
  white-space: nowrap;

  &:active,
  &:hover,
  &.open {
    color: var(--header-link-hover-color);
  }

  @include maxMediaWidth(lg) {
    height: 80px;
    padding-left: 74px;
    margin-right: 0;
    font-size: 2.4rem;

    &.open {
      color: var(--header-color);
      background: var(--color-primary-alpha-20);
    }

    &:active,
    &:hover {
      color: var(--header-secondary-text-dark-color);
      background-color: var(--light-menu-bg-hover-dark-color);
    }
  }

  @include maxMediaWidth(sm) {
    height: 55px;
    padding-left: 16px;
    font-size: 2rem;
  }
}

.dropdownMenu {
  & .pseudoLink {
    color: var(--light-neutral-text-color);
    padding: 5px 10px;
  }

  & .wrap {
    position: static;
    padding: 10px;
    margin: 0 20px;
    box-shadow: none;
  }
}

.wrapArrow {
  margin-left: 5px;
  width: 10px;
}

.wrap {
  display: none;
  position: absolute;
  background: var(--light-bg-color);
  border-radius: 8px;
  top: 73px;
  box-shadow: 0 0 10px 0 var(--color-foreground-alpha-10);
  overflow: hidden;
  z-index: 2;

  &.show {
    display: block;
  }

  @include maxMediaWidth(lg) {
    position: relative;
    top: 0;
    padding: 0;
    box-shadow: none;
    background: transparent;
    border-radius: unset;
  }
}

.wrapLink {
  @include maxMediaWidth(lg) {
    display: flex;
    align-items: center;
    background: var(--color-primary-alpha-10);
  }
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  font-size: 1.6rem;
  color: var(--header-secondary-text-dark-color);

  &:hover {
    color: var(--header-secondary-text-dark-color);
    background-color: var(--light-menu-bg-hover-dark-color);
    text-decoration: none;
  }

  @include maxMediaWidth(lg) {
    height: 55px;
    padding-left: 143px;
    color: var(--secondary-text-color);

    &:hover,
    &:active {
      color: var(--secondary-dark-color);
    }
  }

  @include maxMediaWidth(sm) {
    padding-left: 30px;
  }
}
