@use 'src/css/breakpoint.scss' as *;

.wrap {
  display: none;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  left: 0;
  top: 80px;
  width: 100%;
  background: var(--mobileNav-bg-dark-color);
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  will-change: opacity, height;
  height: 0;
  transition:
    height 0.3s linear,
    opacity 0.3s linear;

  &.isOpen {
    opacity: 1;
    height: calc(100vh - 80px);
  }

  @include maxMediaWidth(lg) {
    display: flex;
  }

  @include maxMediaWidth(sm) {
    top: 70px;

    &.isOpen {
      height: calc(100vh - 70px);
    }
  }
}

.nav {
  display: block;
  width: 100%;
  height: auto;
}

.burger {
  position: relative;
  flex: 0 0 22px;
  height: 16px;
  cursor: pointer;
  display: block;
  z-index: 3;
}

.burgerInit {
  position: absolute;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: rgb(255, 255, 255);
  }

  &::before {
    top: -14px;
    transition:
      top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }

  &::after {
    top: -7px;
    transition:
      top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
      opacity 0.1s linear 0s;
  }
}

.open {
  transform: translate3d(0px, -7px, 0px) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &::before {
    top: 0;
    transform: rotate(-90deg);
    transition:
      top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
      transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  }

  &:after {
    top: 0;
    opacity: 0;
    transition:
      top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0s,
      opacity 0.1s linear 0.22s;
  }
}
