@use 'src/css/breakpoint.scss' as *;

.wrap {
  display: flex;
  align-items: center;
  height: 90px;

  @include maxMediaWidth(lg) {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: center;
  height: 90px;
  width: auto;
}

.loaderWrap {
  margin-right: 10px;

  & svg {
    fill: white;
    opacity: 0.5;
  }
}

.shadowNav {
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 30px;
  font-size: 1.6rem;
  color: var(--secondary-text-color);
  outline: none;
  white-space: nowrap;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    color: var(--header-link-hover-color);
  }

  @include maxMediaWidth(lg) {
    height: 80px;
    padding-left: 74px;
    margin-right: 0;
    font-size: 2.4rem;

    &:active,
    &:hover {
      color: var(--header-secondary-text-dark-color);
      background-color: var(--light-menu-bg-hover-dark-color);
    }
  }

  @include maxMediaWidth(sm) {
    height: 55px;
    padding-left: 16px;
    font-size: 2rem;
  }
}

.navMore {
  gap: 5px;
  cursor: pointer;
  position: relative;

  &.opened {
    & .moreArrow {
      transform: rotate(180deg);
    }

    & .navMoreDropdown {
      display: flex;
    }
  }
}

.navMoreDropdown {
  position: absolute;
  right: 0;
  top: 60px;
  display: none;
  flex-direction: column;
  padding: 0;
  background: var(--light-bg-color);
  border-radius: 8px;
  z-index: 3;
  box-shadow: 0 0 10px 0 var(--color-foreground-alpha-10);
  overflow: hidden;

  & .link {
    color: var(--header-secondary-text-dark-color);
    padding: 10px 15px;
    margin: 0;

    &:hover {
      color: var(--header-secondary-text-dark-color);
      background-color: var(--light-menu-bg-hover-dark-color);
    }
  }
}

.moreArrow {
  transition: transform 0.3s linear;
}
