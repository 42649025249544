@use 'src/css/breakpoint.scss' as *;

.wrap {
  @include maxMediaWidth(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 80px;
    padding: 0 63px;
    border-top: 1px solid color-mod(var(--light-bg-color) a(30%));
  }

  @include maxMediaWidth(sm) {
    padding: 0 6px;
  }
}

.dropdown {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 0;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  position: relative;

  @include maxMediaWidth(lg) {
    width: auto;
  }
}

.wrapCurrentLanguage {
  display: flex;
  align-items: center;
}

.icon {
  flex: 1 1 auto;
  max-width: 20px;
  height: 20px;
  vertical-align: top;

  @include maxMediaWidth(lg) {
    max-width: 35px;
    height: 35px;
  }

  @include maxMediaWidth(sm) {
    max-width: 20px;
    height: 20px;
  }
}

.language {
  padding: 0 7px 0 10px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--secondary-text-color);
  text-transform: capitalize;
  white-space: nowrap;

  &.show {
    color: var(--header-link-hover-color);

    @include maxMediaWidth(lg) {
      color: var(--secondary-text-color);
    }
  }

  @include maxMediaWidth(lg) {
    padding: 0 20px 0 15px;
    font-size: 2rem;
  }

  @include maxMediaWidth(sm) {
    padding: 0 15px 0 10px;
    font-size: 1.6rem;
  }
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  font-size: 1.6rem;
  appearance: menulist-button;
}

.list {
  display: none;
  position: absolute;
  background: var(--light-bg-color);
  color: var(--header-secondary-text-dark-color);
  border-radius: 8px;
  top: 35px;
  z-index: 3;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px var(--color-foreground-alpha-10);

  &.show {
    display: block;
  }

  @include maxMediaWidth(lg) {
    bottom: 0;
    left: 20px;
    top: initial;
    width: 200px;
    border-radius: 8px 8px 0 0;
    transform: translate(-10px, -61px);
  }
}

.listItem {
  display: flex;
  word-break: keep-all;
  padding: 10px 20px;

  &:hover {
    color: var(--header-secondary-text-dark-color);
    background-color: var(--light-menu-bg-hover-dark-color);
    text-decoration: none;
  }
}

.active {
  background-color: var(--light-menu-bg-hover-dark-color);
}

.listName {
  font-size: 1.6rem;
  color: var(--header-secondary-text-dark-color);
}

.listIcon {
  margin-right: 10px;
}

.imgArrow {
  color: var(--secondary-text-color);

  &.show {
    color: var(--header-link-hover-color);

    svg {
      transform: rotate(180deg);
    }
    @include maxMediaWidth(lg) {
      color: var(--secondary-text-color);
    }
  }
}
